import { render, staticRenderFns } from "./DeviceList.vue?vue&type=template&id=4483bcd4&scoped=true&"
import script from "./DeviceList.vue?vue&type=script&lang=js&"
export * from "./DeviceList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4483bcd4",
  null
  
)

export default component.exports