<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_devices_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} devices found
                <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)" :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newDevice()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <b-table id="devices-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="deviceList"
                 :fields="headers"
                 @row-clicked="editDevice">
            <template #cell(createdOnUtc)="{ value, item }">
                {{ utcFormatted(value) }}
            </template>
            <template #cell(firmwareUpdateStatus)="{ value, item }">
                <b-button :disabled="shouldDisableFirmwareUploadBtn(value)" @click="requestFirmwareUpdate(item)">
                    {{ readableFirmwareUpdateState(value) }}
                </b-button>
            </template>
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="devices-table">
            </b-pagination>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
    import deviceService from '@/services/deviceService';
    import router from '@/router';
    import { firmwareUpdateStatus } from '@/variables/variables';

    export default {
        name: "DeviceList",
        data: () => ({
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1,
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            deviceList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
            bcItems: [
                {
                    text: 'devices',
                    href: '/devices',
                    active: true,
                }
            ]
        }),
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    {
                        label: this.$t('common_name'),
                        sortable: true,
                        key: 'name',
                    },
                    {
                        label: this.$t('common_fw_installed_semver'),
                        sortable: true,
                        key: 'firmwareVersion',
                    },
                    {
                        label: this.$t('common_fw_to_install_semver'),
                        sortable: true,
                        key: 'firmwareVersionToInstall',
                    },
                    {
                        label: this.$t('common_fw_update_status'),
                        sortable: true,
                        key: 'firmwareUpdateStatus',
                    },
                    {
                        label: this.$t('common_guid'),
                        sortable: true,
                        key: 'clientId',
                    },
                    {
                        label: this.$t('common_username'),
                        sortable: true,
                        key: 'username',
                    },
                    {
                        label: this.$t('common_password'),
                        sortable: true,
                        key: 'password',
                    },
                    {
                        label: this.$t('common_created'),
                        sortable: true,
                        key: 'createdOnUtc',
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getDevices()
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },

            deleteAllDevices() {
                deviceService.deleteAll();
                this.getDevices();
            },
            newDevice() {
                router.push({ name: 'editDevice', params: { deviceId: 0 } })
            },

            editDevice(e) {
                //console.log(e);
                router.push({ name: 'editDevice', params: { deviceId: parseInt(e.id) } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getDevices();
                }, 600);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getDevices();
            },
            getDevices() {
                deviceService.getDeviceList("FilterDeviceList", {
                    Count: this.options.perPage,
                    Devices: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                    .then((data) => {
                        //console.log(data);
                        this.deviceList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page
                        this.pagination.totalListCount = data.totalListCount

                        this.loading = false
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            readableFirmwareUpdateState(state) {
                //console.log(state);
                switch (state) {
                    case firmwareUpdateStatus.NotRequested:
                        return "Update now";
                    case firmwareUpdateStatus.Requested:
                        return "Requested";
                    case firmwareUpdateStatus.Started:
                        return "Started";
                    case firmwareUpdateStatus.Failed:
                        return "Retry now";
                    case firmwareUpdateStatus.Completed:
                        return "Completed";
                    default:
                        return "Error";
                }

            },
            shouldDisableFirmwareUploadBtn(updateStatus) {
                //console.log(updateStatus);
                return !(updateStatus == firmwareUpdateStatus.NotRequested || updateStatus == firmwareUpdateStatus.Failed);
            },
            requestFirmwareUpdate(item) {
                deviceService.requestFirmwareUpdate(item)
                    .then((data) => {
                        // ToDo: Should this state be updated with SignalR instead? --Martin
                        //console.log(data);
                        var data = data.result;

                        if (data != null && data != undefined) {
                            const updateStatus = data.firmwareUpdateStatus;
                            if (updateStatus != null) {
                                this.deviceList.forEach((oldItem, index) => {
                                    if (oldItem.id === item.id) {
                                        oldItem.firmwareUpdateStatus = data.firmwareUpdateStatus;
                                    }
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        alert(error);
                    });
            }
        }
    }
</script>
